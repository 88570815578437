import React from "react"
import Layout from "../components/layout/layout"
import RegistrationRequest from "../components/registrationRequest/registrationRequest";

export default function RequestRegistration() {
  return (
    <Layout>
      <RegistrationRequest />
    </Layout>
  );
}
