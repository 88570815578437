import React from 'react'
import FormBase from '../formBase/formBase'
import { useSiteConfig } from '../layout/getSiteConfig'

export default function RegistrationRequest() {
  const { registrationRequestInfoApi } = useSiteConfig()

  return (
    <FormBase {...{
      formName: 'Registration Request',
      formFile: '/registration-request.pdf',
      formPath: 'registration-request',
      infoApi: registrationRequestInfoApi,
      fee: 5,
      description: 'To request a lost registration, there is a $5 fee.'
    }} />
  )
}
